import React, { useContext, useState } from 'react';
import { createUser, signIn, resetPassword } from './Firebase';
import './LoginPage.css';
import './App.css';
import logo from './assets/app-logo-1.png'; // Import your logo image
import { LanguageContext } from './LanguageProvider';
import { checkAccount, createAccount } from './Api.js';
import Modal from 'react-modal';
import { BeatLoader } from 'react-spinners';
import { professions, countries } from './globals';


function LoginPage({ handleLogout, setIsUserValid, setUser }) {

    const { translation } = useContext(LanguageContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
    const [isAccountValidating, setIsAccountValidating] = useState(false);
    const [isPasswordResetting, setIsPasswordResetting] = useState(false);
    const [isAccountCreating, setIsAccountCreating] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showSignInMessageModal, setShowSignInMessageModal] = useState(false);
    const [signInModalMessage, setSignInModalMessage] = useState('');

    const [signUpEmail, setSignUpEmail] = useState('');
    const [signUpPassword, setSignUpPassword] = useState('');
    const [signUpRepeatPassword, setSignUpRepeatPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [profession, setProfession] = useState('');
    const [country, setCountry] = useState('');
    const [reasonForJoining, setReasonForJoining] = useState('');
    const [specialty, setSpecialty] = useState('');

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [repeatPasswordError, setRepeatPasswordError] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [professionError, setProfessionError] = useState('');
    const [countryError, setCountryError] = useState('');
    const [reasonForJoiningError, setReasonForJoiningError] = useState('');
    const [specialtyError, setSpecialtyError] = useState('');

    const [flashMessage, setFlashMessage] = useState('');


    function LoadingSpinner() {
        return <BeatLoader color={"#E89400"} loading={true} size={10} />;
    }

    const handleSignUp = (e) => {
        e.preventDefault();

        let formIsValid = true;
        let newLastNameError = '';
        let newFirstNameError = '';
        let newReasonForJoiningError = '';

        // if (!signUpEmail) {
        //     setEmailError('Email is required.');
        //     console.log('Email is required.');
        //     formIsValid = false;
        // }

        if (!validateEmail(signUpEmail)) {
            setEmailError('Please enter a valid email address.');
            console.log('Please enter a valid email address.');
            formIsValid = false;
        }

        // if (!signUpPassword) {
        //     setPasswordError('Password is required.');
        //     console.log('Password is required.');
        //     formIsValid = false;
        // }

        if (!validatePassword(signUpPassword)) {
            setPasswordError('Password should have at least 6 characters, one uppercase, one lowercase, one number.');
            console.log('Password should have at least 6 characters, one uppercase, one lowercase, one number.');
            formIsValid = false;
        }

        if (!signUpRepeatPassword) {
            setRepeatPasswordError('Repeat password is required.');
            console.log('Repeat password is required.');
            formIsValid = false;
        }

        if (signUpPassword !== signUpRepeatPassword) {
            setRepeatPasswordError('Passwords do not match.');
            console.log('Passwords do not match.');
            formIsValid = false;
        }

        const isFirstNameValid = firstName !== '';
        if (!isFirstNameValid) {
            setFirstNameError('First name is required.');
            console.log('First name is required.');
            formIsValid = false;
        }

        if (!firstName.trim) {
            newFirstNameError = 'First name is required.';
            console.log('First name is required.');
            formIsValid = false;
        }

        if (!lastName.trim) {
            newLastNameError = 'Last name is required.';
            console.log('Last name is required.');
            formIsValid = false;
        }

        if (!country) {
            setCountryError('Country is required.');
            console.log('Country is required.');
            formIsValid = false;
        }

        if (!profession) {
            setProfessionError('Profession is required.');
            console.log('Profession is required.');
            formIsValid = false;
        }

        if (!reasonForJoining) {
            setReasonForJoiningError('Reason for joining is required.');
            console.log('Reason for joining is required.');
            formIsValid = false;
        }

        setFirstNameError(newFirstNameError);
        setLastNameError(newLastNameError);

        if (formIsValid) {
            console.log('! Form is valid. Submitting the form...');
            setErrorMessage('');
            setIsAccountCreating(true);
            createUser(signUpEmail, signUpPassword) // create use in Firebase
                .then((userCredential) => {

                    // var user = userCredential.user;
                    console.log('Firebase user created: ', userCredential);
                    // console.log('Firebase user created: ', userCredential.user.email);

                    createAccount(signUpEmail, firstName, lastName, country, profession, reasonForJoining, specialty) // create user in the database
                        .then((data) => {
                            // setIsAccountLoading(false);
                            if (data.status === 'success') {

                                console.log('createAccount success: ', data.message);
                                setShowModal(false);
                                setSignInModalMessage('Your account has been created. Please verify your email by clicking on the link we just sent to your email address')
                                setShowSignInMessageModal(true);// inform user to verify email

                            } else {
                                console.log('createAccount failure: ', data.message);
                                setErrorMessage(data.message);
                            }
                            setIsAccountCreating(false);
                        })
                        .catch((error) => {
                            setIsAccountCreating(false);
                            console.log('createAccount error: ', error);
                            setErrorMessage(error.message);
                        });
                })
                .catch((error) => {
                    setIsAccountCreating(false);
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    console.log('Error: ', errorCode, errorMessage);
                    switch (errorCode) {
                        case 'auth/email-already-in-use':
                            setErrorMessage('Email is already in use by another account');
                            break;
                        case 'auth/invalid-email':
                            setErrorMessage('Please enter a valid email address');
                            break;
                        case 'auth/missing-password':
                            setErrorMessage('Password is missing');
                            break;
                        default:
                            setErrorMessage('Sign up failed. please try again later.');
                            break;
                    }
                });

        }

    }

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const validatePassword = (password) => {

        // at least 6 characters, one uppercase, one lowercase, one number.
        // const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/; 

        // at least 5 characters, one uppercase, one lowercase, one number, one special character
        // const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_\-~=+]).{5,}/;

        // at least 5 characters, one special character
        const re = /(?=.*[!@#$%^&*_\-~=+]).{5,}/;

        return re.test(password);
    }

    const handlePasswordReset = () => {
        setIsPasswordResetting(true);
        resetPassword(email)
            .then(() => {
                setIsPasswordResetting(false);
                setShowPasswordResetModal(false)
                setFlashMessage('Password reset email sent successfully. Please check your email.');
                setTimeout(() => setFlashMessage(''), 2000);
            })
            .catch((error) => {
                // print the error in console
                setIsPasswordResetting(false);
                console.log('Error sending password reset email: ', error);
                setFlashMessage('There was an error sending the password reset email. Please try again later.');
                setTimeout(() => setFlashMessage(''), 2000);
                // An error occurred while sending the password reset email.
                // You can show the error message to the user.
            });
    }

    const handleSubmit = (e) => {
        setErrorMessage('');
        e.preventDefault();
        // setIsAccountLoading(true); 
        setIsAccountValidating(true);
        console.log('Signing in with email: ', email);
        signIn(email, password)
            .then((userCredential) => {
                // Signed in successfully
                // var user = userCredential.user;
                // console.log('User Firebase sign-in done: ', user);
                // now call function checkAccount to check if the user exists in the database
                // if not, show a message to the user to sign up
                checkAccount(email)
                    .then((data) => {
                        setIsAccountValidating(false);
                        // setIsAccountLoading(false);
                        if (data.status === 'success') {
                            console.log('checkAccount success: ', data.message);
                            var user = userCredential.user;
                            console.log('User Firebase sign-in done: ', user);
                            console.log('-> user email verified? ', user.emailVerified);
                            if (user.emailVerified) {
                                setIsUserValid(true);
                                setUser(data.account);
                            } else {
                                setSignInModalMessage('Before you can log in, please verify your email by clicking on the link we sent to your email address')
                                setShowSignInMessageModal(true);
                            }

                        } else {
                            handleLogout();
                            console.log('checkAccount failure: ', data.message);
                            setErrorMessage(data.message);
                            // pending: ask user to create an account
                        }
                    })
                    .catch((error) => {
                        console.log('checkAccount error: ', error);
                        setErrorMessage(error.message);
                    });
            })
            .catch((error) => {
                setIsAccountValidating(false);
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log('Error: ', errorCode, errorMessage);
                switch (errorCode) {
                    case 'auth/invalid-email':
                        setErrorMessage('Please enter a valid email address');
                        break;
                    case 'auth/missing-password':
                        setErrorMessage('Password is missing');
                        break;
                    case 'auth/invalid-credential':
                        setErrorMessage('Invalid email or password provided');
                        break;
                    default:
                        setErrorMessage('Login failed. please try again later.');
                        break;
                }
            });
    }

    return (
        <div>
            <div className="login-container">
                <img src={logo} alt="Logo" className="logo" />
                <div className='app-title'>WiseParrots</div>
                <div className='v-spacer'></div>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Email"
                        className="input-field"
                        onChange={e => { setEmail(e.target.value); setErrorMessage(''); }}
                    />
                    <div className='v-spacer'></div>
                    <input
                        type="password"
                        placeholder={translation.login.password}
                        className="input-field"
                        onChange={e => { setPassword(e.target.value); setErrorMessage(''); }} />
                    <div className='v-spacer'></div>
                    <div className='link-text2' onClick={() => setShowPasswordResetModal(true)}>Forgot your password?</div>
                    <div className='v-spacer'></div>
                    {/* <div className='submit-style'> */}
                    {isAccountValidating ? (
                        <div className='submit-button-loading'><LoadingSpinner /></div>
                    ) : (
                        <input type="submit" className="submit-button-style" value="Continue" />
                    )}
                    {/* <input type="submit" className="submit-button-style" value={isAccountValidating ? <BeatLoader color={"#ffffff"} loading={true} size={10} /> : "Continue"} /> */}
                    {/* </div> */}
                    {errorMessage && <div className='error-text'>{errorMessage}</div>}

                </form>
                {/* {isAccountValidating && <div className='status-text'>Validating account...</div>} */}

                <div className='v-spacer'></div>

                <div className='row-container'>
                    <div className='normal-text'>Don't have an account?</div>
                    <div className='h-spacer'></div>
                    <div
                        className='link-text2'
                        onClick={() => {
                            // clear all the field values and error messages before displaying the form
                            setSignUpEmail('');
                            setSignUpPassword('');
                            setSignUpRepeatPassword('');
                            setFirstName('');
                            setLastName('');
                            setCountry('');
                            setProfession('');
                            setReasonForJoining('');
                            setErrorMessage('');
                            setEmailError('');
                            setPasswordError('');
                            setRepeatPasswordError('');
                            setFirstNameError('');
                            setLastNameError('');
                            setCountryError('');
                            setProfessionError('');
                            setSpecialtyError('');
                            setReasonForJoiningError('');

                            setShowModal(true); // display the sign-up form
                        }}>
                        Sign up here
                    </div>
                </div>
                <div className='row-container'>
                    <div className='small-font'>deployed on 3-10-2024 10:29</div>
                </div>

            </div>

            {showModal && (
                <div className="modal-signup">
                    <div className="modal-signup-content">
                        <h2 className='modal-title'>Sign up for an account</h2>
                        {errorMessage && <div className='error-text'>{errorMessage}</div>}
                        <form onSubmit={handleSignUp}>

                            <div className="row-container">

                                <div className="column-container">
                                    <input
                                        className='input-field-medium' type="email" placeholder="Email address"
                                        onChange={e => { setSignUpEmail(e.target.value); setErrorMessage(''); }}
                                        onBlur={e => validateEmail(e.target.value) ? setEmailError('') : setEmailError('Please enter a valid email address.')}
                                    />
                                    <div className='error-text-small'>{emailError}</div>
                                    <input
                                        className='input-field-medium' type="password" placeholder="Password"
                                        onChange={e => { setSignUpPassword(e.target.value); setPasswordError(''); }}
                                        onBlur={() => validatePassword(signUpPassword) ? setPasswordError('') : setPasswordError('Password should have at least 6 characters with at least one special character.')}
                                    />
                                    <div className='error-text-small'>{passwordError}</div>
                                    <input
                                        className='input-field-medium' type="password" placeholder="Repeat Password"
                                        onChange={e => { setSignUpRepeatPassword(e.target.value); setRepeatPasswordError(''); }}
                                        onBlur={e => e.target.value === signUpRepeatPassword ? setRepeatPasswordError('') : setRepeatPasswordError('Passwords do not match.')}
                                    />
                                    <div className='error-text-small'>{repeatPasswordError}</div>
                                    <input
                                        className='input-field-medium' type="text" placeholder="First Name"
                                        onChange={e => { setFirstName(e.target.value); setFirstNameError(''); }}
                                        onBlur={e => e.target.value ? setFirstNameError('') : setFirstNameError('First name is required.')}
                                    />
                                    <div className='error-text-small'>{firstNameError}</div>
                                    <input
                                        className='input-field-medium' type="text" placeholder="Last Name"
                                        onChange={e => { setLastName(e.target.value); setLastNameError(''); }}
                                        onBlur={e => e.target.value ? setLastNameError('') : setLastNameError('Last name is required.')}
                                    />
                                    <div className='error-text-small'>{lastNameError}</div>

                                </div>

                                <div className="h-spacer-wide"></div>
                                <div className="column-container">
                                    <select
                                        className='dropdown-field-medium'
                                        onChange={e => { setCountry(e.target.value); setCountryError(''); }}
                                        onBlur={e => e.target.value ? setCountryError('') : setCountryError('Country is required.')}
                                    >
                                        <option className="drop-option" value=''>Select country</option>
                                        {countries.map(country => (
                                            <option className="drop-option" value={country}>{country}</option>
                                        ))}
                                    </select>
                                    <div className='error-text-small'>{countryError}</div>

                                    <select
                                        className='dropdown-field-medium'
                                        onChange={e => { setProfession(e.target.value); setProfessionError(''); }}
                                        onBlur={e => e.target.value ? setProfessionError('') : setProfessionError('Profession is required.')}
                                    >
                                        <option className="drop-option" value=''>Select profession</option>
                                        {professions.map(profession => (
                                            <option className="drop-option" value={profession}>{profession}</option>
                                        ))}
                                    </select>
                                    <div className='error-text-small'>{professionError}</div>

                                    <input
                                        className='input-field-medium' type="text" placeholder="Specialty"
                                        onChange={e => { setSpecialty(e.target.value); setSpecialtyError(''); }}
                                        onBlur={e => e.target.value}
                                    />
                                    <div className='error-text-small'>{specialtyError}</div>

                                    <textarea
                                        className='textarea-field' placeholder="Reason for Joining"
                                        onChange={e => { setReasonForJoining(e.target.value); setReasonForJoiningError(''); }}
                                        onBlur={e => e.target.value ? setReasonForJoiningError('') : setReasonForJoiningError('Reason for joining is required.')}
                                    />
                                    <div className='error-text-small'>{reasonForJoiningError}</div>
                                    <div className='v-spacer'></div>


                                </div>


                            </div>
                            <div className='v-spacer'></div>

                            {/* <div className='column-container'> */}

                            {isAccountCreating ? (
                                <div className='submit-button-medium-loading'><LoadingSpinner /></div>
                            ) : (
                                <input className="submit-button-style-medium" type="submit" value="Continue" />
                            )}
                            {/* <input className="submit-button-style-medium" type="submit" value="Submit" /> */}

                            <div className='v-spacer'></div>
                            {/* <button onClick={() => setShowModal(false)}>Cancel </button> */}
                            <div className='link-text2' onClick={() => setShowModal(false)}>Cancel</div>
                            {/* </div> */}
                            {/* <div className="h-spacer-wide"></div> */}


                            <div className='v-spacer'></div>


                        </form>

                    </div>
                </div>
            )}

            {showSignInMessageModal && (
                <div className="modal-signin-message">
                    <div className="modal-signin-message-content">
                        <div>{signInModalMessage}</div>
                        <div className='v-spacer'></div>
                        {/* <div className="button-group"> */}
                        <div className='link-text2' onClick={() => setShowSignInMessageModal(false)}>Close</div>
                        {/* <button onClick={() => setShowSignInMessageModal(false)}>Close </button> */}
                        {/* </div>     */}

                    </div>
                </div>
            )}

            {showPasswordResetModal && (
                <div className='modal-signin-message'>
                    <div className="modal-password-reset-content">
                        <div>Enter your email address</div>
                        <div className='v-spacer'></div>
                        {/* <div className='v-spacer'></div> */}
                        {/* <div className='text-field'> */}
                        <input
                            type="email"
                            className='input-field'
                            placeholder="Email address"
                            onChange={e => setEmail(e.target.value)}
                        />
                        {/* </div> */}
                        <div className='v-spacer'></div>
                        {/* <div className='row-container'> */}

                        {/* //--------------------------------------------------- */}

                        {isPasswordResetting ? (
                            <div className='submit-button-loading'><LoadingSpinner /></div>
                        ) : (
                            <button className="submit-button-style" onClick={handlePasswordReset}>Reset Password</button>
                        )}

                        {/* //--------------------------------------------------- */}

                        {/* <button className="submit-button-style" onClick={handlePasswordReset}>Reset Password</button> */}
                        <div className='v-spacer'></div>
                        <div className='link-text2' onClick={() => setShowPasswordResetModal(false)}>Cancel</div>
                        {/* <div><button onClick={() => setShowPasswordResetModal(false)}>Cancel</button></div> */}
                        {/* </div> */}

                    </div>
                </div>
            )}

            <Modal
                ariaHideApp={false}
                isOpen={flashMessage !== ''}
                onRequestClose={() => setFlashMessage('')}
                className="flash-modal-login"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        transition: 'opacity 5000ms ease-in-out',
                        opacity: flashMessage !== '' ? 1 : 0
                    }
                }}
            >
                <div className='flash-text'>{flashMessage}</div>
            </Modal>

        </div>
    );

}

export default LoginPage;