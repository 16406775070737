import React, { useState, createContext, useEffect } from 'react';
import en from './languages/en.json';
import pt from './languages/pt.json';
import { merge } from 'lodash';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');
  const [translation, setTranslation] = useState(en);

  useEffect(() => {
    let selectedTranslation;
    switch (language) {
      case 'en':
        selectedTranslation = en;
        break;
      case 'pt':
        selectedTranslation = merge({}, en, pt);
        break;
      default:
        selectedTranslation = en;
    }
    setTranslation(selectedTranslation);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage, translation }}>
      {children}
    </LanguageContext.Provider>
  );
};