import React, { useState, useRef, useEffect, useContext, useInsertionEffect } from 'react';
import { UserContext } from './App';
import './WiseParrots.css';
import './App.css';
import ReactLoading from 'react-loading';
import BeatLoader from "react-spinners/BeatLoader";
import Modal from 'react-modal';
import { languages } from './globals';
import icon_mic from './assets/icon-mic.png';
import icon_stop from './assets/icon-stop.png';
import icon_reset from './assets/icon-reset.png';
import icon_copy from './assets/icon-copy.png';
import icon_filesave from './assets/icon-filesave.png';
import icon_fileopen from './assets/icon-fileopen.png';
import icon_bookmark from './assets/icon-bookmark.png';
import icon_openbook from './assets/icon-openbook.png';

import {
    transcribeAudio, refineTranscription, saveTranscription, getTranscriptions,
    getMemories, deleteTranscription, addToMemories, deleteMemory, translateText,
    setDefaultLanguage
} from './Api.js';
import {
    FaMicrophone, FaStopCircle, FaRedo, FaSave, FaRegFolderOpen,
    FaTrash, FaBook, FaCopy, FaFileImport, FaGlobe
} from 'react-icons/fa';


function WiseParrots(props) {

    const [text, setText] = useState('');
    const [translation, setTranslation] = useState('');

    const [recorder, setRecorder] = useState(null);
    const [yourAuthToken, setYourAuthToken] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [isResetTranslationModalOpen, setIsResetTranslationModalOpen] = useState(false);

    const [transcriptions, setTranscriptions] = useState([]);
    const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
    const [loadStatus, setLoadStatus] = useState({ isLoading: false, error: null });

    const [isSaving, setIsSaving] = useState(false);
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const [isSaveComplete, setIsSaveComplete] = useState(false);

    const [isOverwritingMemory, setIsOverwritingMemory] = useState(false);
    const [isOverwriteMemoryModalOpen, setIsOverwriteMemoryModalOpen] = useState(false);
    const [isOverwriteMemoryComplete, setIsOverwriteMemoryComplete] = useState(false);

    const [isDeleting, setIsDeleting] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeleteComplete, setIsDeleteComplete] = useState(false);
    const [transcriptionToDelete, setTranscriptionToDelete] = useState(null);

    const [memories, setMemories] = useState([]);
    const [memoryStatus, setMemoryStatus] = useState({ isLoading: false, error: null });
    const [isMemoryModalOpen, setIsMemoryModalOpen] = useState(false);

    const [isAddingMemory, setIsAddingMemory] = useState(false);
    const [isAddMemoryModalOpen, setIsAddMemoryModalOpen] = useState(false);
    const [isAddMemoryComplete, setIsAddMemoryComplete] = useState(false);

    const [isDeletingMemory, setIsDeletingMemory] = useState(false);
    const [isDeleteMemoryModalOpen, setIsDeleteMemoryModalOpen] = useState(false);
    const [isDeleteMemoryComplete, setIsDeleteMemoryComplete] = useState(false);
    const [memoryToDelete, setMemoryToDelete] = useState(null);

    const [flashMessage, setFlashMessage] = useState('');
    const [flashMessageCopy, setFlashMessageCopy] = useState('');
    const [flashMessageTranslationCopy, setFlashMessageTranslationCopy] = useState('');
    const [flashMessageMic, setFlashMessageMic] = useState('');
    const [flashMessageGo, setFlashMessageGo] = useState('');
    const [flashMessageInputLanguage, setFlashMessageInputLanguage] = useState('');
    const [flashMessageSavedText, setFlashMessageSavedText] = useState('');


    const user = useContext(UserContext);
    const [recording, setRecording] = useState(false);

    const [memoryTitle, setMemoryTitle] = useState('');
    const [memoryTitleError, setMemoryTitleError] = useState('');

    const [isTranslateModalOpen, setIsTranslateModalOpen] = useState(false);
    const [translateLanguage, setTranslateLanguage] = useState('English');
    const [isTranslating, setIsTranslating] = useState(false);
    const [isTranslationComplete, setIsTranslationComplete] = useState(false);

    const [recordingLanguage, setRecordingLanguage] = useState('');
    const [defaultInputLanguage, setDefaultInputLanguage] = useState('English');
    // const languages = ["English", "Portuguese", "Spanish", "French", "German", "Italian", "Dutch", "Chinese (Mandarin)", "Hindi", "Russian", "Arabic", "Japanese", "Turkish", "Indonesian", "Korean"];

    const [previousTranscription, setPreviousTranscription] = useState('');
    // const textareaRef = useRef(null);
    const cursorPosition = useRef(0);

    const [selectedThumb, setSelectedThumb] = useState('Speak');
    const [originalText, setOriginalText] = useState('');
    const [originalPosition, setOriginalPosition] = useState(0);


    Modal.defaultStyles.overlay.backgroundColor = 'rgba(255, 255, 255, 0.35)';

    useEffect(() => {
        if (user && user.defaultInputLanguage) {
            setDefaultInputLanguage(user.defaultInputLanguage);
            setRecordingLanguage(user.defaultInputLanguage);
        }
    }, [user]);

    useEffect(() => {
        console.log('User logic count:', user.loginCount);
        if (user.loginCount === 1) {
            setFlashMessageMic("Click to record");
            setFlashMessageGo("Click to translate");
            setFlashMessageInputLanguage("Select input language");
            setFlashMessageSavedText("See your saved texts");
            // setTimeout(() => setFlashMessageMic(''), 2000);
        }
    }, []);

    useEffect(() => {
        if (props.selectedTranscription) {
            setText(props.selectedTranscription);
        }
    }, [props.selectedTranscription]);

    const handleLoad = async () => {
        const userEmail = user.email;
        console.log('getting transcriptions for: ', userEmail);
        setLoadStatus({ isLoading: true, error: null });
        setIsLoadModalOpen(true);
        setTranscriptionToDelete(null);
        try {
            const response = await getTranscriptions(userEmail);
            if (response.status === 'success') {
                console.log('Transcriptions loaded:', response.message);
                console.log('Transcriptions:', response.transcriptions);
                setTranscriptions(response.transcriptions);
                setLoadStatus({ isLoading: false, error: null });
            } else {
                console.log('Transcriptions load fail:', response.message);
                setLoadStatus({ isLoading: false, error: response.message });
            }
        } catch (error) {
            console.log('Transcriptions load error:', error.message);
            setLoadStatus({ isLoading: false, error: error.message });
        }
    };

    const handleDeleteClick = (id) => {
        setTranscriptionToDelete(id);
        setIsDeleteComplete(false);
        setIsDeleteModalOpen(true);
        setIsDeleting(false);
    };

    const handleTranslate = async () => {
        setIsTranslateModalOpen(true);
        setTranslateLanguage('');
        setIsTranslationComplete(false);
        setIsTranslating(false);
    }

    const handleTranslateLanguageChange = (event) => {
        setTranslateLanguage(event.target.value);
    };

    const handleTranslateContinue = async (language, refinedText) => {
        setFlashMessageGo('');
        console.log('! Translating to: ', language);
        const textToTranslate = refinedText || text;
        console.log('! Translating this text: "', textToTranslate, '"');
        setTranslation('');
        setIsTranslating(true);
        setIsTranslationComplete(false);
        if (language && textToTranslate) {
            try {
                const response = await translateText('', language, textToTranslate);
                if (response.status === 'success') {
                    console.log('Translation completed:', response.message);
                    console.log('> Translation:', response.translation);
                    setTranslation(removeQuotes(response.translation))
                    setFlashMessage('Translation completed');
                    setTimeout(() => setFlashMessage(''), 2000);
                    setIsTranslateModalOpen(false);
                } else {
                    console.log('Translation failed:', response.message);
                    setFlashMessage('Translation failed: ' + response.message);
                    setTimeout(() => setFlashMessage(''), 2000);
                }
            } catch (error) {
                console.error('Error translating the text: ', error);
                setFlashMessage('Translation completed');
                setTimeout(() => setFlashMessage(''), 2000);
            }
        } else {
            if (!language) console.log('No language selected');
            if (!textToTranslate) console.log('No text to translate');
        }
        setIsTranslating(false);
        setIsTranslationComplete(true);
    }

    const handleTranslateCancel = () => {
        setIsTranslateModalOpen(false);
    }

    const handleDeleteContinue = async () => {

        // setIsDeletingTranscription(true);
        setIsDeleting(true);
        setIsDeleteComplete(false);
        // setDeletingTranscriptionId(id);
        const response = await deleteTranscription(transcriptionToDelete);
        if (response.status === 'success') {
            console.log('Transcription deleted:', response.message);
            // console.log('Transcriptions:', response.transcriptions);
            // setTranscriptions(response.transcriptions);
            // Reload the transcriptions after deleting one
            setFlashMessage('The transcription was deleted successfully');
            setTimeout(() => setFlashMessage(''), 2000);
            handleLoad(user.email);
            setIsDeleteModalOpen(false);

        } else {
            console.log('Transcription delete fail:', response.message);
        }
        // setIsDeletingTranscription(false);
        // setDeletingTranscriptionId(null);
        setIsDeleting(false);
        setIsDeleteComplete(true);
        // setIsLoadModalOpen(false);
    }

    const handleDeleteCancel = () => {
        setIsDeleteModalOpen(false);
    };

    const handleTranscriptionClick = (transcription) => {
        setText(transcription);
        setIsLoadModalOpen(false);
    };

    const handleMemoryClick = (t) => {
        setText(t.content);
        setMemoryTitle(t.title);
        // setMemoryTitle(content.substring(0, 20));
        setIsMemoryModalOpen(false);
    };

    const handleMemories = async () => {
        const userEmail = user.email;
        console.log('getting memories for: ', userEmail);
        setMemoryStatus({ isLoading: true, error: null });
        setIsMemoryModalOpen(true);
        try {
            const response = await getMemories(userEmail);
            if (response.status === 'success') {
                console.log('Memories loaded:', response.message);
                console.log('<Memories>:', response.memories);
                setMemories(response.memories);
                setMemoryStatus({ isLoading: false, error: null });
            } else {
                console.log('Memories load fail:', response.message);
                setMemoryStatus({ isLoading: false, error: response.message });
            }
        } catch (error) {
            console.log('Memories load error:', error.message);
            setMemoryStatus({ isLoading: false, error: error.message });
        }
    };

    const handleMemoryTitleChange = (event) => {
        setMemoryTitle(event.target.value);
        if (event.target.value.trim() !== '') {
            setMemoryTitleError('');
        }
    };

    const handleAddToMemories = () => {
        if (!text) {
            console.log('No text to save');
            setFlashMessage('No text to save');
            setTimeout(() => setFlashMessage(''), 2000);
            return;
        }
        setMemoryTitleError('');
        setIsAddMemoryComplete(false);
        setIsAddMemoryModalOpen(true);
    };

    const handleAddMemoryCancel = () => {
        setIsAddMemoryModalOpen(false);
    };

    const handleAddMemoryContinue = async () => {

        if (memoryTitle === '') {
            console.log('Cannot save memory. No memory title provided');
            setMemoryTitleError('Memory title is required');
        } else {
            setMemoryTitleError('');
            setIsAddingMemory(true);
            setIsAddMemoryComplete(false);
            if (user && text) {
                try {
                    const response = await addToMemories(user.email, memoryTitle, text);
                    if (response.status === 'success') {
                        console.log('Memory added successfully');
                        setFlashMessage('Memory added successfully');
                    } else {


                        // if memory title already exists, ask user if they want to overwrite it or create a new memory
                        if (response.message === 'Memory title already exists') {
                            setIsOverwriteMemoryModalOpen(true);

                        } else {
                            console.log('Failed to add memory: ', response.message);
                            setFlashMessage('Add to memory failed: ' + response.message);
                        }
                    }
                    setTimeout(() => setFlashMessage(''), 2000);
                    setIsAddMemoryModalOpen(false);
                } catch (error) {
                    console.error('Error adding memory: ', error);
                    setFlashMessage('Error adding memory');
                    setTimeout(() => setFlashMessage(''), 2000);
                }
            } else {
                if (!user) console.log('No user to save');
                if (!text) console.log('No text to save');
            }
            setIsAddingMemory(false);
            setIsAddMemoryComplete(true);
        }
        // setIsSaveModalOpen(false);
    };

    const handleOverwriteMemoryCancel = () => {
        setIsOverwriteMemoryModalOpen(false);
    };

    ///----------------------------------

    const handleDeleteMemoryClick = (id) => {
        setMemoryToDelete(id);
        setIsDeleteMemoryComplete(false);
        setIsDeleteMemoryModalOpen(true);
        setIsDeletingMemory(false);
    };

    const handleDeleteMemoryContinue = async () => {

        // setIsDeletingTranscription(true);
        setIsDeletingMemory(true);
        setIsDeleteMemoryComplete(false);
        // setDeletingTranscriptionId(id);
        const response = await deleteMemory(memoryToDelete);
        if (response.status === 'success') {
            console.log('Memory deleted:', response.message);
            setFlashMessage('The memory was deleted successfully');
            setTimeout(() => setFlashMessage(''), 2000);
            handleMemories(user.email);
            setIsDeleteMemoryModalOpen(false);

        } else {
            console.log('Memory deletion failed:', response.message);
        }

        setIsDeletingMemory(false);
        setIsDeleteMemoryComplete(true);
        // setIsLoadModalOpen(false);
    }

    const handleOverwriteMemoryContinue = async () => {
        setIsOverwritingMemory(true);
        setIsOverwriteMemoryComplete(false);
        console.log('Overwriting memory...')
        const response = await addToMemories(user.email, memoryTitle, text, true);
        if (response.status === 'success') {
            console.log('Memory saved successfully');
            setFlashMessage('Memory saved successfully');
        } else {
            console.log('Failed to save memory: ', response.message);
            setFlashMessage('Save memory failed: ' + response.message);
        }
        setTimeout(() => setFlashMessage(''), 2000);
        setIsOverwriteMemoryModalOpen(false);
        setIsOverwritingMemory(false);
        setIsOverwriteMemoryComplete(true);
    };

    const handleDeleteMemoryCancel = () => {
        setIsDeleteMemoryModalOpen(false);
    };

    const handleCopy = async () => {

        // these flash messages are for testing only. comment out before deploying!
        // setFlashMessageMic("Click to record");
        // setTimeout(() => setFlashMessageMic(''), 5000);
        // setFlashMessageGo("Click to translate");
        // setTimeout(() => setFlashMessageGo(''), 5000);
        // setFlashMessageInputLanguage("Select input language");
        // setTimeout(() => setFlashMessageInputLanguage(''), 5000);
        // setFlashMessageSavedText("See your saved texts");
        // setTimeout(() => setFlashMessageSavedText(''), 5000);

        try {
            await navigator.clipboard.writeText(text);
            console.log('Transcription copied to clipboard');
            setFlashMessageCopy('Copied!');
            setTimeout(() => setFlashMessageCopy(''), 2000);
        } catch (error) {
            console.error('Failed to copy text: ', error);
        }
    };

    const handleCopyTranslation = async () => {
        try {
            await navigator.clipboard.writeText(translation);
            console.log('Translation copied to clipboard');
            setFlashMessageTranslationCopy('Copied!');
            setTimeout(() => setFlashMessageTranslationCopy(''), 2000);
        } catch (error) {
            console.error('Failed to copy translation: ', error);
        }
    };

    const handleReset = () => {
        setIsResetModalOpen(true);
    };

    const handleResetTranslation = () => {
        setIsResetTranslationModalOpen(true);
    };

    const handleResetContinue = () => {
        setText('');
        setIsResetModalOpen(false);
        setChunks([]);
        setTranslation('');
        setOriginalText('');
        textRef.current = '';
        console.log('Resetting transcription and translation');
        console.log('Chunks:', chunks);
    };

    const handleResetTranslationContinue = () => {
        setTranslation('');
        setOriginalText('');
        setText('');
        setIsResetModalOpen(false);
        setChunks([]);
        textRef.current = '';
        setIsResetTranslationModalOpen(false);
        console.log('Clearing translation and transcription');
    };

    const handleResetCancel = () => {
        setIsResetModalOpen(false);
    };

    const handleResetTranslationCancel = () => {
        setIsResetTranslationModalOpen(false);
    };

    const handleSave = () => {
        if (!text) {
            console.log('No text to save');
            setFlashMessage('No transcription to save');
            setTimeout(() => setFlashMessage(''), 2000);
            return;
        }
        setIsSaveComplete(false);
        setIsSaveModalOpen(true);
    };

    const handleSaveCancel = () => {
        setIsSaveModalOpen(false);
    };

    const handleSaveContinue = async () => {
        setIsSaving(true);
        setIsSaveComplete(false);
        if (user && text) {
            try {
                // setIsSaving(true);
                await saveTranscription(user.email, text);
                console.log('Transcription saved successfully');
                setFlashMessage('Transcription saved successfully');
                setTimeout(() => setFlashMessage(''), 2000);
                setIsSaveModalOpen(false);
                // setIsSaving(false);
            } catch (error) {
                console.error('Error saving transcription: ', error);
            }
        } else {
            if (!user) console.log('No user to save');
            if (!text) console.log('No text to save');
        }
        setIsSaving(false);
        setIsSaveComplete(true);
        // setIsSaveModalOpen(false);
    };

    const autoSaveTranscription = async (refinedText) => {
        // setIsSaving(true);
        // setIsSaveComplete(false);
        if (user && refinedText) {
            try {
                // setIsSaving(true);
                await saveTranscription(user.email, refinedText);
                console.log('Transcription saved successfully');
                setFlashMessage('Transcription saved successfully');
                // setTimeout(() => setFlashMessage(''), 2000);
                // setIsSaveModalOpen(false);
                // setIsSaving(false);
                props.loadTranscriptions();
            } catch (error) {
                console.error('Error saving transcription: ', error);
            }
        } else {
            if (!user) console.log('No user to save');
            if (!text) console.log('No text to save');
        }
        // setIsSaving(false);
        // setIsSaveComplete(true);
        // setIsSaveModalOpen(false);
    };


    const [chunks, setChunks] = useState([]);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [intervalId, setIntervalId] = useState(null);
    const recordingRef = useRef(false);
    // const translationIntervalIdRef = useRef(null);
    const [lastPrintedChunkIndex, setLastPrintedChunkIndex] = useState(-1);
    const lastPrintedChunkLength = useRef(chunks.length);
    const [transcribing, setTranscribing] = useState(false);
    const [refining, setRefining] = useState(false);
    // const [finalAudioBlob, setFinalAudioBlob] = useState(null);

    const textRef = useRef('');

    function hasTranscriptionIssues(transcription, originalText, repetitionLimit, charDistance) {

        let originalTextWithNewTranscription = originalText + transcription;
        // console.log('>>> Comparing original text + full transcription:', originalTextWithNewTranscription);
        // console.log('>>> -- vs orginal tex with transcription chunks: ', text);

        // if ((transcription.length < text.length / 2) && (transcription.length < 100)) {
        console.log('>>> Comparing original text + full transcription:', originalTextWithNewTranscription);
        console.log('>>> -- vs orginal text in the text area: ', text);

        console.log('compare:', originalTextWithNewTranscription.length, text.length / 4, text.length / 2, text.length);
        if ((originalTextWithNewTranscription.length < text.length / 4)) { // transcription is too short
            console.log('>> Transcription is too short');
            return true;
        }

        // transcription has too many repetitions
        const words = transcription.split(' ');
        for (let i = 0; i < words.length; i++) {
            let count = 0;
            for (let j = i + 1; j < words.length; j++) {
                if (words[j] === words[i]) {
                    if (Math.abs(transcription.indexOf(words[j]) - transcription.indexOf(words[i])) <= charDistance) {
                        count++;
                        if (count > repetitionLimit) {
                            console.log('>> Transcription has too many repetitions', words[i]);
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }

    function hasRepeatingWords(transcription, repetitionLimit, charDistance) {

        // transcription has too many repetitions
        const words = transcription.split(' ');
        for (let i = 0; i < words.length; i++) {
            let count = 0;
            for (let j = i + 1; j < words.length; j++) {
                if (words[j] === words[i]) {
                    if (Math.abs(transcription.indexOf(words[j]) - transcription.indexOf(words[i])) <= charDistance) {
                        count++;
                        if (count > repetitionLimit) {
                            console.log('>> Transcription has too many repetitions', words[i]);
                            return true;
                        }
                    }
                }
            }
        }

        // check for too many repeating letters (more than 10) in a word
        for (let i = 0; i < words.length; i++) {
            let count = 0;
            for (let j = 1; j < words[i].length; j++) {
                if (words[i][j] === words[i][j - 1]) {
                    count++;
                    if (count > 10) {
                        console.log('>> Transcription has too many repeating letters in a word', words[i]);
                        return true;
                    }
                }
            }
        }

        return false;
    }

    // a function to cleaup the text by removing unwanted characters
    function cleanupText(text) {
        console.log('---------- Cleaning up text:', text);
        let cleanedText = text.replace(/[^a-zA-Z0-9\s.,?!]/g, '');
        cleanedText = cleanedText.replace(/\.+/g, '.');
        cleanedText = cleanedText.replace(/\?+/g, '?');
        cleanedText = cleanedText.replace(/,+/g, ',');
        cleanedText = cleanedText.replace(/!+/g, '!');
        console.log('---------- Cleaned up text:', cleanedText);
        return cleanedText;
    }

    // useEffect(

    // ) [originalText, translation]);
    // let runningText = '';
    let runningText = useRef('');

    useEffect(() => {
        let intervalId;
        let finalText;

        if (recording || transcribing) {
            intervalId = setInterval(async () => {
                if (chunks.length !== lastPrintedChunkLength.current) {
                    setTranscribing(true);
                    if (lastPrintedChunkLength.current === 0) {
                        // console.log('> Setting original text to:', text);
                        runningText.current = text;
                        // setOriginalText(text);
                        // setOriginalPosition(cursorPosition.current);
                        // console.log('> Original cursor position:', cursorPosition.current);
                    } // Set transcribing to true when starting transcription
                    const newChunks = chunks.slice(lastPrintedChunkIndex + 1);
                    newChunks.forEach(async chunk => {
                        console.log('\nTranscribing chunk ', chunk.id, ' in ', recordingLanguage, ' language');
                        const blob = new Blob([chunk.data], { 'type': 'audio/ogg; codecs=opus' });
                        const promptForWhisper = previousTranscription;
                        const data = await transcribeAudio(blob, recordingLanguage, promptForWhisper, yourAuthToken);
                        console.log('Transcription for chunk ' + chunk.id + ': ' + data.transcription);
                        setPreviousTranscription(data.transcription);
                        if (!hasRepeatingWords(data.transcription, 10, 25)) {
                            // const prevTextLength = textRef.current.length;
                            console.log('! - latest transcription:', data.transcription);
                            console.log('!! - Updating text to position:', cursorPosition.current);
                            const textBeforeCursor = runningText.current.slice(0, cursorPosition.current);
                            console.log('!!! - Text before cursor:', textBeforeCursor);
                            const textAfterCursor = runningText.current.slice(cursorPosition.current);
                            console.log('!!! - Text after cursor:', textAfterCursor);
                            const updatedText = textBeforeCursor + ' ' + data.transcription + textAfterCursor;
                            console.log('!!! - Updated text:', updatedText);
                            runningText.current = updatedText;
                            setText(cleanupText(updatedText));

                            const newTranscriptionLength = data.transcription.trim().length;
                            console.log('!- length of latest transcription:', newTranscriptionLength);
                            cursorPosition.current += newTranscriptionLength;
                            console.log('!--> new cursor position:', cursorPosition.current);

                        }
                    });
                    setLastPrintedChunkIndex(chunks.length - 1);
                    lastPrintedChunkLength.current = chunks.length;

                    if (!recording && chunks.length === lastPrintedChunkLength.current) {
                        setTranscribing(false); // Set transcribing to false when all chunks are transcribed
                        setTimeout(async () => {

                            /// temporary code for testing
                            console.log('\nTranscribing full audio...')
                            setRefining(true);
                            // Create a the final full Blob from the chunks array
                            const fullBlob = new Blob(chunks.map(chunk => chunk.data), { 'type': 'audio/ogg; codecs=opus' });
                            // setFinalAudioBlob(blob);

                            // check if the blob is valid
                            let audio = new Audio();
                            audio.onerror = function () {
                                console.log('\n !!Invalid audio blob\n');
                            };
                            audio.src = URL.createObjectURL(fullBlob);

                            // transcribe the full audio Blob
                            // const promptForWhisper = 'The audio may contain these acronyms: T1 SE, DP/T2 TSE, T2 FLAIR, T2, FFE,  DWI (with ADC map), C1, C2, C3, C4, C5. Do not make up words to fill in gaps in the audio';
                            const promptForWhisper = '';

                            let data;
                            let retries = 0;
                            do {
                                data = await transcribeAudio(fullBlob, recordingLanguage, promptForWhisper, yourAuthToken, "long");
                                retries++;
                                // combine original text with the transcription

                                if (hasTranscriptionIssues(data.transcription, originalText, 10, 25) && retries < 3) {
                                    await new Promise(resolve => setTimeout(resolve, 500)); // Wait for 500ms
                                }
                            } while (hasTranscriptionIssues(data.transcription, originalText, 10, 25) && retries < 3);
                            // const data = await transcribeAudio(fullBlob, recordingLanguage, promptForWhisper, yourAuthToken);


                            console.log('Transcription for the full audio: ', data.transcription);

                            if (!hasTranscriptionIssues(data.transcription, originalText, 10, 25)) {
                                // setText(data.transcription);
                                // setText(data.transcription);
                                setRefining(false);

                                // finalText = originalText + ' ' + data.transcription;
                                // finalText = data.transcription;
                                console.log('!!! - The current cursor position:', cursorPosition.current);
                                console.log('!!! - Original cursor position:', originalPosition)
                                const textBeforeCursor = originalText.slice(0, originalPosition);
                                console.log('!!! - Text before cursor:', textBeforeCursor);
                                const textAfterCursor = originalText.slice(originalPosition);
                                console.log('!!! - Text after cursor:', textAfterCursor);
                                const finalText = textBeforeCursor + ' ' + data.transcription + textAfterCursor;

                                if (finalText && finalText.trim() !== '') {
                                    console.log('\nRefining this text: ', finalText)
                                    setRefining(true);
                                    const refinedText = await refineTranscription(finalText);
                                    setRefining(false);
                                    console.log('Refinement done:', refinedText.response);
                                    setText(removeQuotes(refinedText.response));

                                    // time to translate the transcription
                                    // console.log('Translating the transcription to: ', translateLanguage);
                                    // // console.log('Translating this text: ', refinedText.response);
                                    // await handleTranslateContinue(translateLanguage, refinedText.response);

                                    await autoSaveTranscription(removeQuotes(refinedText.response));

                                    // setRefining(false);
                                } else {
                                    console.log('finalText is null or empty. Skipping refinement.');
                                    setRefining(false);
                                }
                            } else {
                                alert('Issues were encountered during the transcription process.');
                                setRefining(false);
                            }
                        },
                            1000);
                    }
                }
            }, 200);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [recording, chunks, transcribing]);

    const handleRecord = async () => {
        setPreviousTranscription('');
        setFlashMessageMic('');
        if (!recording) {

            // when record is pressed, initialize the chunks array, original text and cursor position
            setChunks([]);
            console.log('> Setting original text to:', text);
            setOriginalText(text);
            setOriginalPosition(cursorPosition.current);
            console.log('> Original cursor position:', cursorPosition.current);

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const recorder = new MediaRecorder(stream);
            setMediaRecorder(recorder);

            recorder.start();
            console.log('Recording started');

            const id = setInterval(() => {
                recorder.stop();
                recorder.start();
            }, 3000);

            setIntervalId(id);

            recorder.ondataavailable = (e) => {
                if (recordingRef.current) {
                    console.log('Saving chunk to array');
                    const chunkWithId = { id: Date.now(), data: e.data };
                    setChunks((prevChunks) => [...prevChunks, chunkWithId]);
                }
            };

            setRecording(true);
            recordingRef.current = true;

        } else {
            mediaRecorder.stop();
            clearInterval(intervalId);
            console.log('Recording stopped');
            setRecording(false);
            console.log('Chunks:', chunks);
        }
    };

    function removeQuotes(str) {
        if (str && str.trim() !== '' && str.startsWith('"') && str.endsWith('"')) {
            return str.slice(1, -1);
        }
        return str;
    }

    const handleRecordingLanguageChange = (event) => {
        setRecordingLanguage(event.target.value);
    };

    const handleClickLanguageChange = (event) => {
        setFlashMessageInputLanguage('');
    };

    const handleDefaultInputLanguageChange = async (language) => {

        const response = await setDefaultLanguage(user.email, language);
        console.log('setDefaultLanguage() Response:', response);
        if (response.status === 'success') {
            console.log('Default input language set to: ', language);
            setDefaultInputLanguage(language);
        } else {
            setFlashMessage('There was an error setting the default input language. Please try again.');
            setTimeout(() => setFlashMessage(''), 2000);
            console.log('Failed to set default input language: ', response.message);
        }
    }



    useEffect(() => {
        return () => {
            if (recorder) {
                recorder.stream.getTracks().forEach(track => track.stop());
            }
        };
    }, [recorder]);

    const handleCursorPosition = (e) => {
        cursorPosition.current = e.target.selectionStart;
        console.log('!--> Cursor position set:', cursorPosition.current);
        // if text is empty, clear chunks array
        if (text.trim() === '') {
            console.log('Text is empty. Clearing audio chunks, transcription text, and translation');
            setChunks([]);
            setTranslation('');
            setOriginalText('');
            setText('');
            textRef.current = '';
        }
    };

    // useEffect(() => {
    //     if (transcribing && text && text.trim() !== '' && text.trim().length > cursorPosition.current) {
    //         cursorPosition.current += data.transcription.trim().length;
    //     }

    // }, [text]); 

    // useEffect(() => {
    //     if (transcribing) {
    //         // Update the cursor position
    //         console.log('Updating cursor position to:', cursorPosition.current);
    //         textareaRef.current.setSelectionRange(cursorPosition.current, cursorPosition.current);
    //     }
    // }, [transcribing]);

    return (
        <div className='app-body'>
            {/* {console.log('Current user:', user)} */}

            {/* <div className='app-menu'>
                <div className='menu-item menu-highlight'>WiseParrots</div>
                <div className='menu-item'>WiseSum</div>
                <div className='menu-item'>Y-Xouras</div>
            </div> */}

            <div className='transcribe-container'>
                <div className='v-spacer'></div>
                {user && <div className='app-body-large-text'>Welcome {user.firstName}</div>}

                <div className='v-spacer'></div>
                <div className='v-spacer'></div>

                <div className='slider'>
                    <div
                        className={`slider-thumb ${selectedThumb === 'Speak' ? 'thumb-on' : 'thumb-off'}`}
                        onClick={() => {
                            setSelectedThumb('Speak');
                            // setFlashMessageMic("Click to record");
                            // setTimeout(() => setFlashMessageMic(''), 5000);
                        }}
                    >
                        Speak
                    </div>
                    <div
                        className={`slider-thumb ${selectedThumb === 'Translate' ? 'thumb-on' : 'thumb-off'}`}
                        onClick={() => setSelectedThumb('Translate')}
                    >
                        Translate
                    </div>
                </div>

                {/* <div className='slider'>
                    <div className='slider-thumb thumb-on'>Speech to text</div>
                    <div className='slider-thumb thumb-off'>Translate</div>
                </div> */}

                <div className='v-spacer'></div>

                <div className='row-transcribe-translate'>

                    <div className='column-transcribe'>
                        <div className='control-bar'>
                            <div className='rec-controls-left'>
                                <div className='icon-button' title="Start/Stop Recording" onClick={handleRecord}>
                                    {recording ?
                                        <img src={icon_stop} className="throbbing" alt="Stop Recording" height="26" /> :
                                        <img src={icon_mic} className="mic-icon" alt="Start Recording" height="26" />
                                    }
                                    {flashMessageMic !== '' &&
                                        <div className='flash-mic-absolute'>
                                            <div className='flash-mic-text'>{flashMessageMic}</div>
                                        </div>
                                    }
                                </div>
                                <div className="h-spacer"></div>
                                {recording && <BeatLoader size={8} color="red" />}
                                {refining &&
                                    <>
                                        <BeatLoader size={10} color="red" />
                                        <div className="recording-text">Refining the transcription</div>
                                    </>
                                }

                            </div>
                            <div className='rec-controls-right'>
                                <div className='icon-button' title="Clear" onClick={handleReset}>
                                    <img src={icon_reset} alt="Clear transcription" height="26" />

                                </div>
                                <select className='language-dropdown' value={recordingLanguage} onChange={handleRecordingLanguageChange} onClick={handleClickLanguageChange}>
                                    {languages.map((language) =>
                                        <option className="option-item" key={language} value={language}>
                                            {language} {language === defaultInputLanguage ? "(Default)" : ""}
                                        </option>
                                    )}
                                </select>
                                {flashMessageInputLanguage !== '' &&
                                    <div className='flash-inputmsg-absolute'>
                                        <div className='flash-mic-text'>{flashMessageInputLanguage}</div>
                                    </div>
                                }
                                <div className="h-spacer-small"></div>
                                <div className='small-font link-color' onClick={() => handleDefaultInputLanguageChange(recordingLanguage)}>
                                    Set as default
                                </div>
                            </div>
                        </div>

                        <div className='v-spacer'></div>

                        <div className='transcription-container'>
                            <div className="row-container full-height ">
                                {/* <textarea className='transcription-text' value={text} onChange={(e) => setText(e.target.value)} /> */}
                                <textarea className='transcription-text' value={text} onChange={(e) => setText(e.target.value)} onClick={handleCursorPosition} onKeyUp={handleCursorPosition} />
                                <div className="column-container">
                                    <div className='icon-button' title="Copy" onClick={handleCopy}>
                                        <img src={icon_copy} alt="Copy transcription" height="25" />
                                    </div>
                                    {flashMessageCopy !== '' &&
                                        <div className='flash-copy-absolute'>
                                            <div className='flash-text'>{flashMessageCopy}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='transcription-options'>
                                {/* <div className='icon-button' title="Save transcription" onClick={handleSave}>
                                    <img src={icon_filesave} alt="Save transcription" height="26" />
                                </div>
                                <div className='icon-button' title="Load transcription" onClick={handleLoad}>
                                    <img src={icon_fileopen} alt="Load transcription" height="26" />
                                </div>
                                <div className="h-spacer"></div>
                                <div className="h-spacer"></div> */}
                                <div className='icon-button' title="Add to memories" onClick={handleAddToMemories} >
                                    <img src={icon_bookmark} alt="Add to memories" height="26" />
                                </div>
                                <div className='icon-button' title="Load memories" onClick={handleMemories} >
                                    <img src={icon_openbook} alt="Load memories" height="26" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {selectedThumb === 'Translate' &&
                        <div className='column-translate'>

                            <div className='control-bar'>
                                <div className='rec-controls-left'>
                                    <div className="circle-container" onClick={() => handleTranslateContinue(translateLanguage)}>
                                        GO
                                        {flashMessageGo !== '' &&
                                            <div className='flash-go-absolute'>
                                                <div className='flash-mic-text'>{flashMessageGo}</div>
                                            </div>
                                        }
                                    </div>

                                    <div className="h-spacer"></div>
                                    {isTranslating && <BeatLoader size={8} color="red" />}
                                    {/* {refining &&
                                        <>
                                            <BeatLoader size={10} color="red" />
                                            <div className="recording-text">Completing the transcription</div>
                                        </>
                                    } */}

                                </div>
                                <div className='rec-controls-right'>
                                    <div className='icon-button' title="Clear" onClick={handleResetTranslation}>
                                        <img src={icon_reset} alt="Clear translation" height="26" />

                                    </div>
                                    <select className='language-dropdown' value={translateLanguage} onChange={handleTranslateLanguageChange}>
                                        {languages.map((language) =>
                                            <option className="option-item" key={language} value={language}>
                                                {language}
                                            </option>
                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className='v-spacer'></div>

                            <div className='transcription-container'>
                                <div className="row-container full-height ">
                                    <textarea className='transcription-text' value={translation} onChange={(e) => setTranslation(e.target.value)} />
                                    <div className="column-container">
                                        <div className='icon-button' title="Copy" onClick={handleCopyTranslation}>
                                            <img src={icon_copy} alt="Copy translation" height="25" />
                                        </div>
                                        {flashMessageTranslationCopy !== '' &&
                                            <div className='flash-copy-absolute'>
                                                <div className='flash-text'>{flashMessageTranslationCopy}</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='transcription-options'>
                                    {/* <div className='icon-button' title="Save transcription" onClick={handleSave}>
                                        <img src={icon_filesave} alt="Start Recording" height="26" />
                                    </div>
                                    <div className='icon-button' title="Load transcription" onClick={handleLoad}>
                                        <img src={icon_fileopen} alt="Start Recording" height="26" />
                                    </div>
                                    <div className="h-spacer"></div>
                                    <div className="h-spacer"></div>
                                    <div className='icon-button' title="Add to memories" onClick={handleAddToMemories} >
                                        <img src={icon_bookmark} alt="Start Recording" height="26" />
                                    </div>
                                    <div className='icon-button' title="Load memories" onClick={handleMemories} >
                                        <img src={icon_openbook} alt="Start Recording" height="26" />
                                    </div> */}
                                </div>
                            </div>


                        </div>
                    }
                </div>

                <Modal ariaHideApp={false} isOpen={isResetModalOpen} onRequestClose={handleResetCancel} className="confirm-modal">
                    <div className='modal-confirm-text'>This will clear the current transcription and translation</div>
                    <div className='modal-action-row'>
                        <div className='modal-action-link' onClick={handleResetContinue}>Continue</div>
                        <div className='modal-action-link-reverse' onClick={handleResetCancel}>Cancel</div>
                    </div>
                </Modal>

                <Modal ariaHideApp={false} isOpen={isResetTranslationModalOpen} onRequestClose={handleResetTranslationCancel} className="confirm-modal">
                    <div className='modal-confirm-text'>This will clear the current transcription and translation</div>
                    <div className='modal-action-row'>
                        <div className='modal-action-link' onClick={handleResetTranslationContinue}>Continue</div>
                        <div className='modal-action-link-reverse' onClick={handleResetTranslationCancel}>Cancel</div>
                    </div>
                </Modal>

                <Modal ariaHideApp={false} isOpen={isSaveModalOpen} onRequestClose={handleSaveCancel} className="confirm-modal">
                    <div className='modal-confirm-text'>You are about to save the transcription</div>
                    <div className='modal-action-row'>
                        <div className='modal-action-link' onClick={handleSaveContinue}>Continue</div>
                        <div className='modal-action-link-reverse' onClick={handleSaveCancel}>Cancel</div>
                    </div>
                    {isSaving ? (
                        <>
                            <div className="recording-text">Saving</div>
                            <BeatLoader size={10} />
                        </>
                    ) : isSaveComplete ? (
                        <div className="recording-text">Done saving.</div>
                    ) : null}
                </Modal>

                <Modal ariaHideApp={false} isOpen={isTranslateModalOpen} onRequestClose={handleTranslateCancel} className="list-modal">

                    <div className='modal-header'>Select the language to translate to</div>
                    {languages.map(language => (
                        <div className='list-item' onClick={() => handleTranslateContinue(language)}>{language}</div>
                    ))}

                    <div className='v-spacer'></div>
                    {isTranslating ? (
                        <>
                            <div className="recording-text">Translating</div>
                            <BeatLoader size={10} />
                        </>
                    ) : isTranslationComplete ? (
                        <div className="recording-text">Done translating.</div>
                    ) : null}
                </Modal>

                <Modal ariaHideApp={false} isOpen={isLoadModalOpen} onRequestClose={() => setIsLoadModalOpen(false)} className="list-modal">
                    <div className='modal-header'>Your Saved Transcriptions</div>

                    <div >
                        {loadStatus.isLoading ? (
                            <div>Loading...</div>
                        ) : loadStatus.error ? (
                            <div>{loadStatus.error}</div>
                        ) : (
                            transcriptions.map((t, index) => (
                                <div className='list-items' key={index}>
                                    <span><div className='h-spacer'></div></span>
                                    <span>{index + 1}.</span>
                                    <span style={{ marginLeft: '10px' }} onClick={() => handleTranscriptionClick(t.transcription)}>
                                        {t.transcription.substring(0, 40)}...
                                    </span>
                                    <span
                                        style={{ marginLeft: 'auto', cursor: 'pointer' }}
                                        onClick={() => handleDeleteClick(t.id)}>
                                        <FaTrash size={18} />
                                        {/* {deletingTranscriptionId === t.id ? <ReactLoading type={"spin"} color="#000000" className='deleting' /> : <FaTrash size={16} />} */}
                                    </span>
                                </div>
                            ))
                        )}
                    </div>

                </Modal>

                <Modal ariaHideApp={false} isOpen={isDeleteModalOpen} onRequestClose={handleDeleteCancel} className="confirm-modal">
                    <div className='modal-confirm-text'>You are about to delete the transcription</div>
                    <div className='modal-action-row'>
                        <div className='modal-action-link' onClick={handleDeleteContinue}>Continue</div>
                        <div className='modal-action-link-reverse' onClick={handleDeleteCancel}>Cancel</div>
                    </div>
                    {isDeleting ? (
                        <>
                            <div className="recording-text">Deleting</div>
                            <BeatLoader size={10} />
                        </>
                    ) : isDeleteComplete ? (
                        <div className="recording-text">Done deleting.</div>
                    ) : null}
                </Modal>

                <Modal ariaHideApp={false} isOpen={isAddMemoryModalOpen} onRequestClose={handleAddMemoryCancel} className="confirm-modal">
                    <div className='modal-header'>Save this memory</div>
                    <div className='normal-text'>This saves the text as a memory that you can retrieve in the future</div>
                    <div className='v-spacer'></div>
                    <div className='modal-input-field'>
                        {/* <label className='normal-text' htmlFor="memoryTitle">Title </label> */}
                        <input className='wp-input-field' placeholder='Type the title' type="text" id="memoryTitle" value={memoryTitle} onChange={handleMemoryTitleChange} />
                        {memoryTitleError && <div className="modal-error-text">{memoryTitleError}</div>}
                    </div>
                    <div className='v-spacer'></div>
                    <div className='modal-action-row'>
                        <div className='modal-action-link' onClick={handleAddMemoryContinue}>Save</div>
                        <div className='modal-action-link-reverse' onClick={handleAddMemoryCancel}>Cancel</div>
                    </div>
                    {isAddingMemory ? (
                        <>
                            <div className="recording-text">Adding memory</div>
                            <BeatLoader size={10} />
                        </>
                    ) : isAddMemoryComplete ? (
                        <div className="recording-text">Done adding memory.</div>
                    ) : null}
                </Modal>


                <Modal ariaHideApp={false} isOpen={isMemoryModalOpen} onRequestClose={() => setIsMemoryModalOpen(false)} className="list-modal">
                    <div className='modal-header'>Memories</div>
                    <div className='normal-text'>These are the saved Memories you can retrieve and display in the text box</div>
                    <div className='v-spacer'></div>
                    <div >
                        {memoryStatus.isLoading ? (
                            <div>Loading...</div>
                        ) : memoryStatus.error ? (
                            <div>{memoryStatus.error}</div>
                        ) : (
                            memories.map((t, index) => (
                                <div className='list-items' key={index} onClick={() => handleMemoryClick(t)}>
                                    <span>{index + 1}.</span>
                                    <span style={{ marginLeft: '10px' }}>{t.title.substring(0, 35)}</span>
                                    <span
                                        style={{ marginLeft: 'auto', cursor: 'pointer' }}
                                        onClick={() => handleDeleteMemoryClick(t.id)}>
                                        <FaTrash size={16} />
                                    </span>
                                </div>
                            ))
                        )}
                    </div>

                </Modal>

                <Modal ariaHideApp={false} isOpen={isDeleteMemoryModalOpen} onRequestClose={handleDeleteMemoryCancel} className="confirm-modal">
                    <div className='modal-confirm-text'>You are about to delete the memory</div>
                    <div className='modal-action-row'>
                        <div className='modal-action-link' onClick={handleDeleteMemoryContinue}>Continue</div>
                        <div className='modal-action-link-reverse' onClick={handleDeleteMemoryCancel}>Cancel</div>
                    </div>
                    {isDeletingMemory ? (
                        <>
                            <div className="recording-text">Deleting memory</div>
                            <BeatLoader size={10} />
                        </>
                    ) : isDeleteMemoryComplete ? (
                        <div className="recording-text">Done deleting.</div>
                    ) : null}
                </Modal>

                <Modal ariaHideApp={false} isOpen={isOverwriteMemoryModalOpen} onRequestClose={handleOverwriteMemoryCancel} className="confirm-modal">
                    <div className='modal-confirm-text'>Do you want to overwrite the existing memory? If not, please change the memory title and try again.</div>
                    <div className='modal-action-row'>
                        <div className='modal-action-link' onClick={handleOverwriteMemoryContinue}>Overwrite</div>
                        <div className='modal-action-link' onClick={handleOverwriteMemoryCancel}>Cancel</div>
                    </div>
                    {isOverwritingMemory ? (
                        <>
                            <div className="recording-text">Saving memory</div>
                            <BeatLoader size={10} />
                        </>
                    ) : isOverwriteMemoryComplete ? (
                        <div className="recording-text">Done saving.</div>
                    ) : null}
                </Modal>

                {/* <Modal
                    ariaHideApp={false}
                    isOpen={flashMessage !== ''}
                    onRequestClose={() => setFlashMessage('')}
                    className="flash-absolute"
                    style={{
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            transition: 'opacity 1000ms ease-in-out',
                            opacity: flashMessage !== '' ? 1 : 0
                        }
                    }}
                >
                    <div className='flash-text'>{flashMessage}</div>
                </Modal> */}
                {/* <div className='v-spacer'></div> */}
                {/* <textarea className='transcription-box' value={text} onChange={(e) => setText(e.target.value)} /> */}
            </div>


        </div>
    );
}

export default WiseParrots;


// Reactloading types
// blank: A blank animation.
// balls: Animation of bouncing balls.
// bars: Animation of bouncing bars.
// bubbles: Animation of bouncing bubbles.
// cubes: Animation of bouncing cubes.
// cylon: A cylon animation.
// spin: A spinning animation.
// spinningBubbles: Animation of spinning bubbles.
// spokes: A spokes animation.