
// cloudnext server (for development/testing) 
// const BASE_URL = 'https://wise-parrots-api-kplerp33sa-no.a.run.app';
// const BASE_URL = 'https://wise-parrots-api-1090853246262.europe-southwest1.run.app';

// // client's server (for production)
const BASE_URL = 'https://wise-parrots-api-5q3goua2lq-no.a.run.app';


// creat a function to validate email format

export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}


// create functiont to send feedback. the endpoint is BASE_URL/send/send-email
// take in parameters email, name, message
// validate that email is in the correct format, name and message are not empty
// if validations pass, call the endpoint passing email, name, message as parameters. method is POST

export const sendFeedback = async (email, name, message) => {

    if (!email || !name || !message) {
        return {
            status: 'fail',
            message: 'Email, name and message are required'
        };
    }

    // validate email format
    if (!validateEmail(email)) {
        return {
            status: 'fail',
            message: 'Email is not in the correct format'
        };
    }

    const response = await fetch(`${BASE_URL}/email/send-email`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, name, message })
    });

    const data = await response.json();

    if (!response.ok) {
        return {
            status: 'fail',
            message: `API request failed: ${response.status} ${response.statusText}`
        };
    }

    return data;

}



// create function setDefaultLanguage to set the default language for the app
// call the endpoint https://wise-parrots-api-kplerp33sa-no.a.run.app/user/set-default-language
// pass the following parameters: email, language
// method is POST

export const setDefaultLanguage = async (email, language) => {

    console.log('>> setting default language', email, language);

    if (!email || !language) {
        return {
            status: 'fail',
            message: 'Email and language are required'
        };
    }

    // validate email format
    if (!validateEmail(email)) {
        return {
            status: 'fail',
            message: 'Email is not in the correct format'
        };
    }

    const response = await fetch(`${BASE_URL}/user/set-default-input-language`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, language })
    });

    const data = await response.json();
    console.log('API response', data);

    if (!response.ok) {
        return {
            status: 'fail',
            message: `API request to change default langauge failed: ${response.status} ${response.message}`
        };
    }

    return data;
}


// create function createAccount to create a new account.
// call the endpoint https://wise-parrots-api-kplerp33sa-no.a.run.app/user/create-account 
// pass the following parameters:  email, firstName, lastName, profession, joinReason
// method is POST

export const createAccount = async (email, firstName, lastName, country, profession, joinReason, specialty) => {
    const missingFields = [];
    if (!email) {
        missingFields.push('email');
    }
    if (!firstName) {
        missingFields.push('firstName');
    }
    if (!lastName) {
        missingFields.push('lastName');
    }
    if (!country) {
        missingFields.push('country');
    }
    if (!profession) {
        missingFields.push('profession');
    }
    if (!joinReason) {
        missingFields.push('joinReason');
    }

    if (missingFields.length > 0) {
        return {
            status: 'fail',
            message: `The following fields are missing: ${missingFields.join(', ')}`
        };
    }

    // validate email format
    if (!validateEmail(email)) {
        return {
            status: 'fail',
            message: 'Email is not in the correct format'
        };
    }

    const response = await fetch(`${BASE_URL}/user/create-account`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, firstName, lastName, country, profession, joinReason, specialty })
    });

    const data = await response.json();

    if (!response.ok) {
        return {
            status: 'fail',
            message: `Account creation failed: ${response.status} ${response.statusText}`
        };
    }

    return data;
}


// create function checkAccount to check if account exists. 
// call the endpoint https://wise-parrots-api-kplerp33sa-no.a.run.app/user/check-account passing the email as parameter
// method is POST

export const checkAccount = async (email) => {
    if (!email) {
        return {
            status: 'fail',
            message: 'Email is required'
        };
    }

    // validate email format
    if (!validateEmail(email)) {
        return {
            status: 'fail',
            message: 'Email is not in the correct format'
        };
    }

    const response = await fetch(`${BASE_URL}/user/check-account`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
    });

    const data = await response.json();

    if (!response.ok) {
        return {
            status: 'fail',
            message: `API request to check account failed: ${response.status} ${response.statusText}`
        };
    }

    return data;

}


// create function refineTranscription to call the endpoint https://wise-parrots-api-kplerp33sa-no.a.run.app/ai/refine-transcription
// pass the message as a parameter to the endpoint. method is POST
// return the response from the endpoint

export const refineTranscription = async (message) => {
    const response = await fetch(`${BASE_URL}/ai/refine-transcription`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ prompt: message })
    });

    const data = await response.json();
    console.log('>> AI response', data.response);

    if (!response.ok) {
        return {
            status: 'fail',
            message: `API request failed: ${response.status} ${response.statusText}`
        };
    }

    return data;
}


// create function translateText to translate text from one language to another.
// parameters are sourceLanguage, targetLanguage, text. targetLanguage and text are required
// call the endpoint https://wise-parrots-api-kplerp33sa-no.a.run.app/ai/translate-text
// pass sourceLanguage, targetLanguage, text as parameters to the endpoint. method is POST

export const translateText = async (sourceLanguage, targetLanguage, text) => {
    if (!targetLanguage || !text) {
        return {
            status: 'fail',
            message: 'Target language and text are required'
        };
    }

    const response = await fetch(`${BASE_URL}/ai/translate-text`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ sourceLanguage, targetLanguage, text })
    });

    const data = await response.json();

    if (!response.ok) {
        return {
            status: 'fail',
            message: `API request failed: ${response.status} ${response.statusText}`
        };
    }

    return data;
}


export const transcribeAudio = async (audioFile, language, prompt, yourAuthToken, audioLength) => {

    // log to console the audio file and language
    console.log('>> audio file', audioFile);
    console.log('>> language', language);

    const formData = new FormData();
    formData.append('file', audioFile);
    formData.append('audioLength', audioLength);

    if (language) {
        formData.append('language', language);
        // console.log('> currently transcribing audio in', language);
    } else {
        // console.log('> currently transcribing audio ');
    }

    if (prompt && prompt.trim() !== '') {
        formData.append('prompt', prompt);
        console.log('> prompt provided: ', prompt);
    }

    const response = await fetch(`${BASE_URL}/ai/transcribe-audio`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${yourAuthToken}`
        },
        body: formData
    });

    const data = await response.json();

    console.log('> transcription status', data.status);
    // console.log('> transcription result', data.transcription);

    if (!response.ok) {
        console.log('API request failed', response)
        return {
            status: 'fail',
            message: `API request failed: ${response.status} ${response.statusText}`,
            transcription: ''
        };
    }

    return data;
};

// write a function saveTranscription that accepts 2 parameters, email and transcription
// validate that email is in the correct format and transcription is not empty
// if validations pass, call the endpoint https://wise-parrots-api-kplerp33sa-no.a.run.app/ai/transcribe-audio
// pass email and transcription as parameters to the endpoint. method is POST

export const saveTranscription = async (email, transcription) => {
    if (!email || !transcription) {
        return {
            status: 'fail',
            message: 'Email and transcription are required'
        };
    }

    const response = await fetch(`${BASE_URL}/db/save-transcription`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, transcription })
    });

    const data = await response.json();

    if (!response.ok) {
        return {
            status: 'fail',
            message: `API request failed: ${response.status} ${response.statusText}`
        };
    }

    return data;
}

// create a function called getTranscriptions that accepts an email as a parameter
// validate that email is in the correct format
// if validation passes, call the endpoint https://wise-parrots-api-kplerp33sa-no.a.run.app/db/get-transcriptions
// pass email as a parameter to the endpoint. method is POST

export const getTranscriptions = async (email) => {
    if (!email) {
        return {
            status: 'fail',
            message: 'Email is required'
        };
    }

    const response = await fetch(`${BASE_URL}/db/get-transcriptions`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
    });

    const data = await response.json();

    if (!response.ok) {

        return {
            status: 'fail',
            message: `API request failed: ${response.status} ${response.statusText}`
        };
    }

    return data;

}

// create a function to delete a transcription. the endpoint is BASE_URL/db/delete-transcription
// accept id as a parameter. method is POST. pass id to the endpoint
// return the response from the endpoint

export const deleteTranscription = async (id) => {
    const response = await fetch(`${BASE_URL}/db/delete-transcription`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id })
    });

    const data = await response.json();

    if (!response.ok) {
        return {
            status: 'fail',
            message: `API request failed: ${response.status} ${response.statusText}`
        };
    }

    return data;
}


// create a function to addToMemories that accepts the following parameters: email, title, content
// validate that email is in the correct format and title and content are not empty
// if validations pass, call the endpoint https://wise-parrots-api-kplerp33sa-no.a.run.app/db/add-memory
// pass email, title and content as parameters to the endpoint. method is POST

export const addToMemories = async (email, title, content, overwriteFlag) => {
    if (!email || !title || !content) {
        return {
            status: 'fail',
            message: 'Email, title and content are required'
        };
    }

    // if overwriteFlag is not provided, default to false
    if (overwriteFlag === undefined) {
        overwriteFlag = false;
    }

    const response = await fetch(`${BASE_URL}/db/add-memory`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, title, content, overwriteFlag })
    });

    const data = await response.json();
    console.log('API response', data);

    if (!response.ok) {
        return {
            status: 'fail',
            message: `API request failed: ${response.status} ${response.statusText}`
        };
    }

    return data;
}

// create a function to delete a memory. the endpoint is BASE_URL/db/delete-memory
// accept id as a parameter. method is POST. pass id to the endpoint
// return the response from the endpoint

export const deleteMemory = async (id) => {
    const response = await fetch(`${BASE_URL}/db/delete-memory`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id })
    });

    const data = await response.json();
    console.log('API response', data);

    if (!response.ok) {
        return {
            status: 'fail',
            message: `API request failed: ${response.status} ${response.statusText}`
        };
    }

    return data;
}

// create a function called getMemories that accepts an email as a parameter
// validate that email is in the correct format
// if validation passes, call the endpoint https://wise-parrots-api-kplerp33sa-no.a.run.app/db/get-memories
// pass email as a parameter to the endpoint. method is POST

export const getMemories = async (email) => {
    if (!email) {
        return {
            status: 'fail',
            message: 'Email is required'
        };
    }

    const response = await fetch(`${BASE_URL}/db/get-memories`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
    });

    const data = await response.json();

    if (!response.ok) {

        return {
            status: 'fail',
            message: `API request failed: ${response.status} ${response.statusText}`
        };
    }

    console.log('memories data', data);

    return data;

}