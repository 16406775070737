import { initializeApp } from 'firebase/app';
import {
    getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword,
    sendEmailVerification, sendPasswordResetEmail
} from 'firebase/auth';

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: "wise-parrots.firebaseapp.com",
//     projectId: "wise-parrots",
//     storageBucket: "wise-parrots.appspot.com",
//     messagingSenderId: "1090853246262",
//     appId: "1:1090853246262:web:42e009102fb45561ba4f97",
//     measurementId: "G-TJ2N0XP93B"
// };

const firebaseConfig = {
    // apiKey: "AIzaSyB25jlLcP0-Suhx5iku87VtZlNZ-Zlx4Ug",
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "wiseparrots.firebaseapp.com",
    projectId: "wiseparrots",
    storageBucket: "wiseparrots.appspot.com",
    messagingSenderId: "196418106097",
    appId: "1:196418106097:web:6262b37340ff40a8e0af50",
    measurementId: "G-Q0C19NJXPW"
};


// console.log('PROCESS.ENV: ', process.env);
// console.log('FIREBASE API KEY: ', process.env.REACT_APP_FIREBASE_API_KEY);

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);




export const signIn = (email, password) => {
    // console.log('FIREBASE API KEY: ', process.env.REACT_APP_FIREBASE_API_KEY);
    return signInWithEmailAndPassword(auth, email, password);
}


// export const createUser = (email, password) => {
//   return createUserWithEmailAndPassword(auth, email, password);
// }


export const createUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Send email verification
            return sendEmailVerification(userCredential.user)
                .then(() => {
                    console.log('Verification email sent');
                    return userCredential;
                })
                .catch((error) => {
                    console.error('Error sending verification email', error);
                });
        });
}


export const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
}