// import logo from './logo.svg';
import './App.css';
import './WiseParrots.css';
import Modal from 'react-modal';
import { BeatLoader } from 'react-spinners';
import React, { useContext, useEffect, useState } from 'react';
import LoginPage from './LoginPage';
import WiseParrots from './WiseParrots';
import { LanguageContext, LanguageProvider } from './LanguageProvider';
import { auth } from './Firebase'; // Import the auth object
import { onAuthStateChanged, signOut } from 'firebase/auth';
import logo from './assets/app-logo-1.png';
import { getTranscriptions, deleteTranscription, sendFeedback, validateEmail } from './Api.js';
import icon_menu from './assets/menu.png';
import { FaTrash } from 'react-icons/fa';

export const UserContext = React.createContext();

function App() {

  /* Skip Login */
  // const [user, setUser] = useState(null);
  const hardcodedUser = {
    email: "goybsdev@gmail.com",
    displayName: "Demo User",
  };
  const [user, setUser] = useState(hardcodedUser);
  /* End Skip Login */

  const [isUserValid, setIsUserValid] = useState(true); // true by default because we are skipping login   
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [transcriptions, setTranscriptions] = useState([]);
  const [loadStatus, setLoadStatus] = useState({ isLoading: false, error: null });
  const [transcriptionToDelete, setTranscriptionToDelete] = useState(null);
  const [selectedTranscription, setSelectedTranscription] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteComplete, setIsDeleteComplete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [flashMessage, setFlashMessage] = useState('');
  const [flashMessageSavedText, setFlashMessageSavedText] = useState('');

  /* Skip Login */
  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
  //     setUser(currentUser);
  //     // setIsAccountLoading(false);
  //   });

  //   // Cleanup subscription on unmount
  //   return () => unsubscribe();
  // }, []);
  /* End Skip Login */

  useEffect(() => {
    if (user) {
      console.log('User login count:', user.loginCount);
      if (user.loginCount === 1) {
        setFlashMessageSavedText("See your saved texts");
      }
    }
  }, [user]);

  const handleLogout = () => {
    console.log('Logging out');
    signOut(auth).then(() => {
      console.log('User signed out');
      setIsUserValid(false);
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
      console.log('Error signing out: ', error);
    });
  };

  const loadTranscriptions = async () => {
    const userEmail = user.email;
    console.log('getting transcriptions for: ', user.email);
    setLoadStatus({ isLoading: true, error: null });
    // setIsLoadModalOpen(true);
    setTranscriptionToDelete(null);
    try {
      const response = await getTranscriptions(userEmail);
      if (response.status === 'success') {
        console.log('Transcriptions loaded:', response.message);
        console.log('Transcriptions:', response.transcriptions);
        const sortedTranscriptions = response.transcriptions.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
        // setTranscriptions(response.transcriptions);
        setTranscriptions(sortedTranscriptions);
        setLoadStatus({ isLoading: false, error: null });
      } else {
        console.log('Transcriptions load fail:', response.message);
        setLoadStatus({ isLoading: false, error: response.message });
      }
    } catch (error) {
      console.log('Transcriptions load error:', error.message);
      setLoadStatus({ isLoading: false, error: error.message });
    }
  };

  useEffect(() => {
    if (isUserValid) {
      loadTranscriptions();
    }
  }, [isUserValid]);

  const handleDeleteClick = (id) => {
    setTranscriptionToDelete(id);
    setIsDeleteComplete(false);
    setIsDeleteModalOpen(true);
    setIsDeleting(false);
  };

  const handleDeleteContinue = async () => {

    // setIsDeletingTranscription(true);
    setIsDeleting(true);
    setIsDeleteComplete(false);
    // setDeletingTranscriptionId(id);
    const response = await deleteTranscription(transcriptionToDelete);
    if (response.status === 'success') {
      console.log('Transcription deleted:', response.message);
      // console.log('Transcriptions:', response.transcriptions);
      // setTranscriptions(response.transcriptions);
      // Reload the transcriptions after deleting one
      setFlashMessage('The transcription was deleted successfully');
      setTimeout(() => setFlashMessage(''), 2000);
      loadTranscriptions();
      setIsDeleteModalOpen(false);

    } else {
      console.log('Transcription delete fail:', response.message);
    }
    // setIsDeletingTranscription(false);
    // setDeletingTranscriptionId(null);
    setIsDeleting(false);
    setIsDeleteComplete(true);
    // setIsLoadModalOpen(false);
  }

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <LanguageProvider>
      <UserContext.Provider value={user}>
        <div className="App">
          {/* <div className="app-column"> */}
          {isUserValid && (
            <div className={`panel ${isPanelOpen ? 'open' : ''}`}>
              <img className="panel-icon" src={icon_menu} alt="Menu" width="24" height="24"
                onClick={() => {
                  setFlashMessageSavedText("");
                  setIsPanelOpen(!isPanelOpen);
                  // setFlashMessageSavedText("See your saved texts");
                  // setTimeout(() => setFlashMessageSavedText(''), 5000);
                }} />
              {flashMessageSavedText !== '' &&
                <div className='flash-savedtext-absolute'>
                  <div className='flash-mic-text'>{flashMessageSavedText}</div>
                </div>
              }
              <div className='v-spacer'></div>
              {isPanelOpen && (
                // -----------------------------------------------------
                <div className='transcription-list' >
                  {loadStatus.isLoading ? (
                    <div>Loading...</div>
                  ) : loadStatus.error ? (
                    <div>{loadStatus.error}</div>
                  ) : (
                    transcriptions.map((t, index) => (
                      <div className='transcription-entry' key={index}>
                        <span
                          onClick={() => setSelectedTranscription(t.transcription)}
                        >
                          {t.transcription.length > 20 ? `${t.transcription.substring(0, 20)}...` : t.transcription}
                        </span>
                        <span className="trash-icon"
                          style={{ marginLeft: 'auto', cursor: 'pointer' }}
                          onClick={() => handleDeleteClick(t.id)}
                        >
                          <FaTrash size={14} />
                          {/* {deletingTranscriptionId === t.id ? <ReactLoading type={"spin"} color="#000000" className='deleting' /> : <FaTrash size={16} />} */}
                        </span>
                      </div>
                    ))
                  )}
                </div>

              )}
            </div>
          )}
          {/* </div> */}
          <div className="app-column">
            {isUserValid ? <LoggedInHeader handleLogout={handleLogout} /> : <LoggedOutHeader />}

            {isUserValid ? <WiseParrots loadTranscriptions={loadTranscriptions} selectedTranscription={selectedTranscription} /> : <LoginPage handleLogout={handleLogout} setIsUserValid={setIsUserValid} setUser={setUser} />}

          </div>
        </div>

        <Modal ariaHideApp={false} isOpen={isDeleteModalOpen} onRequestClose={handleDeleteCancel} className="confirm-modal">
          <div className='modal-confirm-text'>You are about to delete the transcription</div>
          <div className='modal-action-row'>
            <div className='modal-action-link' onClick={handleDeleteContinue}>Continue</div>
            <div className='modal-action-link-reverse' onClick={handleDeleteCancel}>Cancel</div>
          </div>
          {isDeleting ? (
            <>
              <div className="recording-text">Deleting</div>
              <BeatLoader size={10} />
            </>
          ) : isDeleteComplete ? (
            <div className="recording-text">Done deleting.</div>
          ) : null}
        </Modal>

      </UserContext.Provider>
    </LanguageProvider>
  );

}

function LoggedInHeader(/*{ handleLogout }*/) {
  const { setLanguage } = useContext(LanguageContext);
  const user = useContext(UserContext);
  console.log('user', user);

  const [feeedbackEmail, setFeedbackEmail] = useState('');
  const [feeedbackName, setFeedbackName] = useState('');
  const [feeedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackEmailError, setFeedbackEmailError] = useState('');
  const [feedbackNameError, setFeedbackNameError] = useState('');
  const [feedbackMessageError, setFeedbacMessageError] = useState('');
  const [isSendingFeedback, setIsSendingFeedback] = useState(false);
  // const [isAddMemoryComplete, setIsAddMemoryComplete] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  // const [feebackModalCancel, setFeebackModalCancel] = useState(false);
  const [isSendingError, setIsSendingError] = useState(false);
  const [flashMessageSent, setFlashMessageSent] = useState('');

  const handleSubmitFeedback = () => {
    setIsFeedbackModalOpen(true);
  }

  const handleSendFeedback = () => {
    console.log('Sending feedback');
    // reset error messages
    setFeedbackEmailError('');
    setFeedbackNameError('');
    setFeedbacMessageError('');
    setIsSendingError(false);

    let formIsValid = true;

    // check that all fields are filled
    if (!feeedbackEmail) {
      setFeedbackEmailError('Please enter your email address');
      formIsValid = false;
    } else {
      if (!validateEmail(feeedbackEmail)) {
        setFeedbackEmailError('Please enter a valid email address');
        formIsValid = false;
      }
    }

    if (!feeedbackName) {
      setFeedbackNameError('Please enter your name');
      formIsValid = false;
    }

    if (!feeedbackMessage) {
      setFeedbacMessageError('Please enter a message');
      formIsValid = false;
    }

    // if all fields are filled, send feedback by calling function sendFeedback.
    // get response status. If successful, show success message. If not, show error message.

    if (formIsValid) {
      setIsSendingFeedback(true);
      setIsSendingError(false);
      sendFeedback(feeedbackEmail, feeedbackName, feeedbackMessage)
        .then((response) => {
          console.log('response', response);
          if (response.status === 'success') {
            console.log('Feedback sent successfully');
            setIsSendingFeedback(false);
            setIsFeedbackModalOpen(false);
            setFlashMessageSent('You feedback was sent');
            setTimeout(() => setFlashMessageSent(''), 2000);
          } else {
            console.log('Error sending feedback', response.message);
            setIsSendingError(true);
            setIsSendingFeedback(false);
            // setIsFeedbackModalOpen(false);
          }
        })
        .catch((error) => {
          console.log('Error sending feedback', error);
          setIsSendingFeedback(false);
          // setIsFeedbackModalOpen(false);
        });
    }

  }

  const handleCancelFeedback = () => {
    setIsFeedbackModalOpen(false);
  }

  const handleFeedbackEmailChange = (e) => {
    setFeedbackEmailError('');
    setIsSendingError(false);
    setFeedbackEmail(e.target.value);
  }

  const handleFeedbackNameChange = (e) => {
    setFeedbackNameError('');
    setIsSendingError(false);
    setFeedbackName(e.target.value);
  }

  const handleFeedbackNessageChange = (e) => {
    setFeedbacMessageError('');
    setIsSendingError(false);
    setFeedbackMessage(e.target.value);
  }


  return (
    <header className='header-container'>
      <div className='header-left'>
        <img className='logo-small' src={logo} alt="App Icon" />
        <div>Wise Parrots</div>
      </div>

      <div className='header-right'>
        {/* <button className='language-button header-link' onClick={() => setLanguage('en')}>EN</button>
        <button className='language-button header-link' onClick={() => setLanguage('pt')}>PT</button> */}
        {flashMessageSent !== '' &&
          <div className='flash-message-sent'>
            <div className='flash-text'>{flashMessageSent}</div>
          </div>
        }
        <button className='button-small-reverse' onClick={handleSubmitFeedback}>Feedback</button>
        
        {/* Skip logout, since we also skipped login
        <div className="h-spacer"></div>
        <button className='button-small' onClick={handleLogout}>Log out</button>
        */}
      </div>

      <Modal ariaHideApp={false} isOpen={isFeedbackModalOpen} onRequestClose={handleCancelFeedback} className="wp-email-modal">
        <div className='modal-header'>Send Feedback</div>
        <div className='normal-text'>We value your feedback! Let us know how we can get better</div>
        <div className='v-spacer'></div>
        <div className='modal-input-field'>
          <input className='wp-input-field' placeholder='Your email' type="text" value={feeedbackEmail} onChange={handleFeedbackEmailChange} />
          {feedbackEmailError && <div className="modal-error-text">{feedbackEmailError}</div>}
        </div>
        <div className="v-spacer"></div>
        <div className='modal-input-field'>
          <input className='wp-input-field' placeholder='Your name' type="text" value={feeedbackName} onChange={handleFeedbackNameChange} />
          {feedbackNameError && <div className="modal-error-text">{feedbackNameError}</div>}
        </div>
        <div className="v-spacer"></div>
        <div className='modal-input-field'>
          <textarea className='wp-textarea' placeholder='Your message' type="text" value={feeedbackMessage} onChange={handleFeedbackNessageChange} />
          {feedbackMessageError && <div className="modal-error-text">{feedbackMessageError}</div>}
        </div>
        <div className='v-spacer'></div>
        <div className='modal-action-row'>
          <div className='modal-action-link' onClick={handleSendFeedback}>Send</div>
          <div className='modal-action-link-reverse' onClick={handleCancelFeedback}>Cancel</div>
        </div>
        {isSendingFeedback ? (
          <>
            {/* <div className="status-text">Sending</div> */}
            <BeatLoader size={10} />
          </>
        ) : null}
        {isSendingError ? (
          <>
            <div className="error-text">There was a problem sending your feedback. Please try again later</div>
          </>
        ) : null}

      </Modal>
    </header>
  );
}


function LoggedOutHeader() {
  const { setLanguage } = useContext(LanguageContext);

  return (
    <header className='header-container-default'>
      {/* <button className='language-button header-link' onClick={() => setLanguage('en')}>EN</button>
      <button className='language-button header-link' onClick={() => setLanguage('pt')}>PT</button> */}
    </header>
  );
}

export default App;
